/* eslint-disable lingui/text-restrictions */

import { t } from "@lingui/macro";

import { openai } from "./client";

const PROMPT = `你是一名专门为简历撰写文案的人工智能写作助理。
除了你改进的文本外，不要返回任何其他内容。它不应该以换行符开头。它不应该有任何前缀或后缀文本。
将以下段落的语气改为{mood}，并以文本返回:

Text: """{input}"""

Revised Text: """`;

type Mood = "casual" | "professional" | "confident" | "friendly";

export const changeTone = async (text: string, mood: Mood) => {
  const prompt = PROMPT.replace("{mood}", mood).replace("{input}", text);

  const result = await openai().chat.completions.create({
    messages: [{ role: "user", content: prompt }],
    model: "gpt-4o",
    max_tokens: 1024,
    temperature: 0.5,
    stop: ['"""'],
    n: 1,
  });

  if (result.choices.length === 0) {
    throw new Error(t`OpenAI did not return any choices for your text.`);
  }

  return result.choices[0].message.content ?? text;
};
